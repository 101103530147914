import axiosIns from "@/libs/axios";

export function requestLoadSupportRequests(reqData) {
    return axiosIns.post(
        `/admin/support/requests`,
        reqData,
        {
        }
    );
}

export function requestMarkSupportRequestAsResolved (reqData) {
    return axiosIns.post(
        `/admin/support/resolve`,
        reqData,
        {
        }
    );
}

export function requestRespondSupportRequest(reqData) {
    return axiosIns.post(
        `/admin/support/respond`,
        reqData,
        {
        }
    );
}

export function requestUpdateSupportRequestType(reqData) {
    return axiosIns.post(
        `/admin/support/change_type`,
        reqData,
        {
        }
    );
}