import { ref, computed } from "@vue/composition-api";
import { requestLoadSupportRequests, requestMarkSupportRequestAsResolved, requestRespondSupportRequest, requestUpdateSupportRequestType } from "@/service/SupportService";

const supportRequests = ref([]);
export const filterBy = ref(null);
const supportTablePerPage = ref(25);
const showAll = ref(false);
const lastItem = ref(0);
const loading = ref(false);
const lastItemsFound = ref([0]);

export const getSupportRequestsList = computed(() => supportRequests.value);
export const getStoreLoading = computed(() => loading.value);
export const getShowAll = computed(() => showAll.value);
export const getSupportTablePerPage = computed(() => supportTablePerPage.value);
export const setSupportRequests = (val) => (supportRequests.value = val);
export const setShowAll = (val) => (showAll.value = val);
export const setFilterBy = (val) => (filterBy.value = val);
export const setSupportTablePerPage = (val) => (supportTablePerPage.value = val);
export const setLastItem = (val) => (lastItem.value = val);
export const sortBy = ref("created");
export const sortOrder = ref("asc");

export const onLoadSupportRequests = async ({ prevPage, nextPage }) => {
  loading.value = true;
  try {
    let lastItemForPage = 0;
    if (lastItemsFound.value && lastItemsFound.value.length > 2) {
      if (prevPage) {
        lastItemForPage = lastItemsFound.value[lastItemsFound.value.indexOf(lastItem.value) - 2];
      } else if (nextPage) {
        lastItemForPage = lastItem.value;
      } else {
        lastItemForPage = lastItemsFound.value[lastItemsFound.value.indexOf(lastItem.value) - 1];
      }
    }
    let reqData = {
      lastItem: lastItemForPage,
      perPage: supportTablePerPage.value,
      sort: sortBy.value,
      sortOrder: sortOrder.value,
      filter: {
        showAll: showAll.value,
        search: filterBy.value || null
      }
    };
    const {
      data: { supportRequests, pagination }
    } = await requestLoadSupportRequests(reqData);
    setSupportRequests(supportRequests);
    setSupportTablePerPage(pagination.perPage);
    setLastItem(pagination.lastItem);
    if (lastItemsFound.value.indexOf(lastItem.value) === -1) {
      lastItemsFound.value.push(pagination.lastItem);
    }
    console.log(lastItemsFound.value);
  } catch (error) {
    //handle error
    console.log("error loading support requests", error);
  } finally {
    loading.value = false;
  }
};

export const onRespondSupportRequest = async ({ response, isInternal, supportUid, resolved }) => {
  loading.value = true;
  try {
    console.log("responding");
    const req = {
      supportUid: supportUid,
      response: response,
      isInternal: isInternal,
      resolved: resolved
    };
    console.log("responding", req);
    await requestRespondSupportRequest(req);
  } catch (error) {
    //handle error
    console.log("error", error);
    //handleError("Error loading.", error);
  } finally {
    loading.value = false;
  }
};

export const onUpdateSupportType = async (type, supportUid) => {
  loading.value = true;
  try {
    const req = {
      supportUid: supportUid,
      newType: type
    };
    await requestUpdateSupportRequestType(req);
  } catch (error) {
    //handle error
    console.log("error", error);
    //handleError("Error loading.", error);
  } finally {
    loading.value = false;
  }
};
