<template>
  <b-card>
    <b-overlay rounded="sm" opacity="0.8">
      <b-card class="card-company-table">
        <template v-if="ticket">
          <b-row class="match-height">
            <b-col lg="11">
              <h3>By {{ ticket.requestingUser }} / {{ ticket.requestingFacilityName }} ({{ ticket.requestingFacilityLicense }})</h3>
            </b-col>
          </b-row>
          <b-row class="match-height">
            <b-col lg="8">
              <br />
              <h6>
                <b>{{ ticket.requestingUser }}</b> requested this on {{ showDateInFormat(ticket.created) }}
              </h6>
              <span v-html="messageAsHtml(ticket.message)" />
              <b-list-group>
                <b-list-group-item
                  v-for="item in ticketMessages"
                  :key="item.message + item.sent"
                  class="flex-column align-items-start"
                  :variant="item.internalMessage ? 'warning' : ''"
                >
                  <div class="d-flex w-100">
                    <h6 class="mb-1">{{ item.fromUserName }}</h6>
                    <div class="pl-1">
                      <b-badge class="badge-margin" v-if="item.internalMessage" variant="primary" pill>Internal Message</b-badge>

                      <small>@{{ showDateInFormat(item.sent) }}</small>
                    </div>
                  </div>

                  <p class="mb-1 pl-1">
                    {{ item.message || item.internalMessage }}
                  </p>
                </b-list-group-item>
              </b-list-group>

              <div>
                <b-form-textarea class="mt-2" id="responseInput" v-model="response" type="text" placeholder="Type message" />
                <b-button-group class="mr-2 mb-1 justify-content-start">
                  <b-form-checkbox class="mt-2" v-model="isInternal"> Internal? </b-form-checkbox>
                  <b-form-checkbox class="mt-2 ml-1" v-model="markResolved"> Resolve? </b-form-checkbox>
                </b-button-group>
                <br />
                <span>
                  <b-button class="mt-1" variant="success" @click="onSendResponse" :disabled="getStoreLoading">
                    <b-spinner v-if="isResolutionPending" small style="margin-bottom: 3px"></b-spinner>
                    Send Response
                  </b-button>
                  <b-button class="mt-1 ml-2 justify-content-end" variant="warning" @click="onClose">
                    <b-spinner v-if="isResolutionPending" small style="margin-bottom: 3px"></b-spinner>
                    Close
                  </b-button>
                </span>
              </div>
            </b-col>
            <b-col lg="4">
              <h6><b>Status</b></h6>
              <span>
                <b-badge :variant="ticket.responded ? 'primary' : 'warning'">{{ ticket.responded ? "Responded" : "Pending" }}</b-badge
                >&nbsp;
                <b-badge :variant="ticket.resolved ? 'primary' : 'warning'">{{ ticket.resolved ? "Resolved" : "Unresolved" }}</b-badge>
              </span>
              <br />
              <br />
              <h6><b>Request Type</b></h6>
              <span>
                <b-badge :variant="requestTypes[0][ticket.requestType]">
                  {{ ticket.requestType }}
                </b-badge>
              </span>
              <br />
              <br />
              <h6><b>Requesting Facility</b></h6>
              <span> <b-img rounded="circle" :src="ticket.requestingFacilityLogoUrl" class="d-inline-block mr-1 mb-1" witdh="45" height="45"> </b-img> </span>

              {{ ticket.requestingFacilityName }}<br />
              <small>{{ ticket.requestingFacilityLicense }}</small>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-overlay>
  </b-card>
</template>

<script>
import { onMounted, ref, watch } from "@vue/composition-api";
import moment from "moment";
import { setSelectedSupportRequest, getStoreLoading, onRespondSupportRequest } from "@/views/support/useSupportRequests";
import { useRouter } from "@core/utils/utils";
export default {
  name: "SupportConversation",
  props: {
    ticketRow: null
  },
  setup(props, { root, emit }) {
    const ticket = ref(null);
    const ticketMessages = ref([]);
    const response = ref("");
    const isInternal = ref(true);
    const markResolved = ref(false);
    const isResolutionPending = ref(false);
    const { router } = useRouter();

    const requestTypes = [
      {
        BUG: "light-warning",
        FEATURE: "light-success",
        ERROR: "light-danger",
        REQUEST: "light-primary",
        QUESTION: "light-info"
      }
    ];

    const onSendResponse = async () => {
      isResolutionPending.value = true;
      await onRespondSupportRequest({ response: response.value, isInternal: isInternal.value, supportUid: props.ticketRow.supportUid, resolved: markResolved.value });
      isResolutionPending.value = false;
    };

    const onClose = () => {
      router.back();
    };
    const showDateInFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm:ss a");

    const messageAsHtml = (msg) => msg.replaceAll(/\n/g, "<br/>");
    onMounted(() => {
      if (props.ticketRow) {
        console.log(props.ticketRow);
        ticket.value = props.ticketRow;
        ticketMessages.value = props.ticketRow.conversation;
      } else {
        ticketMessages.value = [];
      }
    });

    return {
      ticketMessages,
      ticket,
      showDateInFormat,
      requestTypes,
      messageAsHtml,
      isResolutionPending,
      onSendResponse,
      onClose,
      response,
      getStoreLoading,
      isInternal,
      markResolved
    };
  }
};
</script>

<style scoped>
.badge-margin {
  margin-right: 5px;
}
</style>
